



























































import { Component, Vue } from "vue-property-decorator";
import BlindBoxApi, { DeliverDetail } from "@/api/blind-box.api";
import { Toast, Collapse, CollapseItem } from "vant";
import EmptyBox from "@/components/EmptyBox.vue";
import { OrderResult } from "@/api/mine.api";
import MySupplyItem from "@/components/MySupplyItem.vue";
import { copy, observer } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: { MySupplyItem, EmptyBox, Collapse, CollapseItem },
})
export default class MyDeliveryRecordDetail extends Vue {
  id = this.$route.params.id;
  item: DeliverDetail | null = null;
  error = false;
  active = [1];

  get activeText(): string {
    return this.active.includes(1) ? "收起" : "展开";
  }

  handleCopy(str: string): void {
    copy(str);
    Toast.success("已复制");
  }

  get addressArea(): string {
    const getText = (arr: string[] | null) => (!arr ? "" : arr[1] || "");
    return `${getText(this.item?.provinces || [])}${getText(
      this.item?.city || []
    )}${getText(this.item?.area || [])}${this.item?.detailed_address || ""}`;
  }

  async mounted(): Promise<void> {
    observer("refreshInit", () => this.init());
    await this.init();
  }
toService(){
    location.href = "https://work.weixin.qq.com/kfid/kfc3cc5741444048d77";
  }
  async init() {
    if (InteractionLib.isApp()){
      if (this.id === "-1") {
      if (!JSON.parse((this.$route.query.all as any)).address_id) {
        this.error = true;
        return;
      }
      const {
        address_id,
        detail: { product_cover: cover, product_name: title },
        express_number,
        express_company,
        address,
      } = JSON.parse((this.$route.query.all as any))  as unknown as OrderResult;
      const { provinces = "[]", area = "[]", city = "[]" } = address || {};
      this.item = {
        cover,
        address_id,
        title,
        express_name: express_company,
        express_number,
        express_phone: "QQ-861673318",
        character: {
          value: -2,
          color: "",
          background: "",
          text: "",
        },
        ...{
          ...address,
          provinces: JSON.parse(provinces),
          area: JSON.parse(area),
          city: JSON.parse(city),
        },
      } as any;
      return;
    }
    }else{
      if (this.id === "-1") {
      if (!(this.$route.query.all as any).address_id) {
        this.error = true;
        return;
      }
      const {
        address_id,
        detail: { product_cover: cover, product_name: title },
        express_number,
        express_company,
        address,
      } = (this.$route.query.all as any)  as unknown as OrderResult;
      const { provinces = "[]", area = "[]", city = "[]" } = address || {};
      this.item = {
        cover,
        address_id,
        title,
        express_name: express_company,
        express_number,
        express_phone: "QQ-861673318",
        character: {
          value: -2,
          color: "",
          background: "",
          text: "",
        },
        ...{
          ...address,
          provinces: JSON.parse(provinces),
          area: JSON.parse(area),
          city: JSON.parse(city),
        },
      } as any;
      return;
    }
    }
    
    const toast = Toast.loading({ mask: true });
    this.item = await BlindBoxApi.getDeliverDetail(this.id).catch(() => {
      this.error = true;
      return null;
    });
    toast.close();
  }
}
